import {Component} from '@angular/core';
import {StaticDialog} from "../../services/static-dialog.service";
import {FindArticlesArticle} from "../../api/generated";
import {environment} from "../../../environments/environment";
import {FormControl} from "@angular/forms";
import {ArticleValue} from "../../components/extra-article-tile/article-tile.component";
import {pairwise, takeUntil} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {SwiperContainer} from "swiper/element/bundle";
import {Navigation, Pagination} from 'swiper/modules';


export type ArticleInfoDialogComponentInput = FindArticlesArticle & {quantityControl: FormControl<ArticleValue>};
export type ArticleInfoDialogComponentOutput = {quantity: number} | null;

@Component({
  selector: 'app-article-info-dialog',
  templateUrl: './article-info-dialog.component.html',
  styleUrls: ['./article-info-dialog.component.scss']
})
export class ArticleInfoDialogComponent implements StaticDialog<ArticleInfoDialogComponentInput, ArticleInfoDialogComponentOutput> {

  article: ArticleInfoDialogComponentInput | null = null;

  imageBaseUrl = environment.articleImageBaseUrl;

  quantityControl = new FormControl<number>(0, {nonNullable: true, updateOn: 'blur'});
  swiperConfig: Partial<SwiperContainer> = {
    modules: [Pagination, Navigation],
    pagination: {
      dynamicBullets: true
    },
    navigation: {
      enabled: true
    }
  };

  constructor(
    public dialogRef: MatDialogRef<ArticleInfoDialogComponentOutput>,
    public translateService: TranslateService
  ) {
    this.quantityControl.valueChanges
      .pipe(
        takeUntil(this.dialogRef.afterClosed()),
        pairwise()
      )
      .subscribe(([previous, value]) => this.tweakNewValue(previous, value));
  }

  getDialogInput(input: ArticleInfoDialogComponentInput | null): void {
    this.article = input;

    const existingQuantity = this.article?.quantityControl.value?.quantity ?? 0;

    this.quantityControl.setValue(existingQuantity === 0 ? (this.article?.minimumOrderQuantity ?? 0): existingQuantity);
  }

  private tweakNewValue(previous: number, value: number) {
    const direction = Math.sign(value - previous);

    const valueIsUnderMinimum = value < (this.article?.minimumOrderQuantity ?? 0);
    const valueIsNotZero = value !== 0;

    let newValue = value;

    if (valueIsUnderMinimum && valueIsNotZero) {
      if (direction == 1) {
        newValue = this.article?.minimumOrderQuantity ?? 0;
      } else {
        newValue = 0;
      }
    }


    const hasMaximumOrderQuantity = (this.article?.maximumOrderQuantity ?? 0) > 0;
    const valueIsAboveMaximum = value > (this.article?.maximumOrderQuantity ?? Number.MAX_SAFE_INTEGER);

    if (hasMaximumOrderQuantity && valueIsAboveMaximum) {
      newValue = this.article?.maximumOrderQuantity ?? Number.MAX_SAFE_INTEGER;
    }

    const numberHasRemainder = newValue % 1 !== 0;

    if (numberHasRemainder) {
      newValue = Math.floor(newValue);
    }

    if (newValue !== value) {
      this.quantityControl.setValue(newValue);
    }
  }

  get articleImageUrls(): string[] {
    return [
      ...this.article?.extraImageUrls?.map(extraImage => this.imageBaseUrl + extraImage) ?? []
    ];
  }

  get articleTitle(): string | null | undefined {
    if ((this.article?.shortDescription?.trim() ?? '') === '') {
      return this.article?.name;
    }

    return this.article?.shortDescription;
  }

  private get changesMade(): boolean {
    return this.quantityControl.value !== (this.article?.quantityControl.value.quantity ?? 0);
  }

  private get isAdding(): boolean {
    return this.quantityControl.value > (this.article?.quantityControl.value.quantity ?? 0);
  }

  private get isRemoving(): boolean {
    return this.changesMade && this.quantityControl.value === 0;
  }

  get buttonText(): string {
    if (this.isAdding) {
      return this.translateService.instant('EXTRA_ARTICLE.DIALOG.ADD_TO_BASKET');
    }

    if (this.isRemoving) {
      return this.translateService.instant('EXTRA_ARTICLE.DIALOG.REMOVE_FROM_BASKET');
    }

    if (this.changesMade) {
      return this.translateService.instant('EXTRA_ARTICLE.DIALOG.EDIT_BASKET_QUANTITY');
    }

    if (this.quantityControl.value === 0) {
      return this.translateService.instant('EXTRA_ARTICLE.DIALOG.DO_NOT_ADD_TO_BASKET');
    }

    return this.translateService.instant('EXTRA_ARTICLE.DIALOG.ADD_TO_BASKET');
  }

  add(modifier: number) {
    const newValue = this.quantityControl.value + modifier;

    const decrementing = modifier < 0;
    const underMinimumQuantity = newValue < (this.article?.minimumOrderQuantity ?? 0);

    if (decrementing && underMinimumQuantity) {
      this.quantityControl.setValue(0);
    } else {
      this.quantityControl.setValue(newValue);
    }
  }

  submit() {
    this.dialogRef.close({quantity: this.quantityControl.value});
  }

  getValue(): ArticleInfoDialogComponentOutput | null {
    return {quantity: this.quantityControl.value};
  }
}
