import {Injectable} from '@angular/core';
import {Article, CalculateDiscountsRequest, CheckoutRequest, PaymentApiService} from "../api/generated";
import {delay, map, Observable, share, shareReplay, startWith, switchMap} from "rxjs";
import {TryAgainObservable, tryRecoverFromErrors} from "../helpers/RecoverableObservable";
import {ErrorService} from "./error.service";
import {TranslateService} from "@ngx-translate/core";
import {hasRequiredPropertiesFn} from "../helpers/UtilityFunctions";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public creditCardArticle: Observable<Article>;

  constructor(
    private paymentApiService: PaymentApiService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) {
    this.creditCardArticle = this.translateService.onLangChange
      .pipe(
        startWith(this.translateService.currentLang),
        switchMap(() => {
          return paymentApiService.getApiPaymentCreditcardProductDetails()
            .pipe(
              tryRecoverFromErrors(() => errorService.showErrorDialogWithRetry())
            );
        }),
        shareReplay(1)
      );
  }

  public checkout(checkoutDetails: CheckoutRequest, onError: (err: unknown) => TryAgainObservable): Observable<boolean> {
    const checkoutResult = this.paymentApiService
      .postApiPaymentCheckout(checkoutDetails)
      .pipe(
        tryRecoverFromErrors(onError),
        delay(0),
        share()
      );

    checkoutResult.subscribe(result => {
      if (result.paymentUrl !== null && result.paymentUrl !== undefined) {
        location.href = result.paymentUrl;
      }
    });

    return checkoutResult.pipe(map(hasRequiredPropertiesFn(['paymentUrl'])));
  }

  public getDiscounts(basket: CalculateDiscountsRequest) {
    return this.paymentApiService
      .postApiPaymentCalculate(basket)
      .pipe(
        map(response => response.discounts ?? [])
      );
  }

  public calculate(calculateDetails: CheckoutRequest): Observable<number> {
    return this.paymentApiService
      .postApiPaymentCalculate(calculateDetails)
      .pipe(
        map(response => response.price)
      );
  }
}
