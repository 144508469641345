<app-order-summary/>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.GIFT_CARD.TITLE' | translate}}
      </mat-panel-title>

      <mat-panel-description>
        {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.GIFT_CARD.SHORT_DESCRIPTION' | translate}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <h2>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.GIFT_CARD.TITLE' | translate}}</h2>
    <p>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.GIFT_CARD.BODY' | translate}}</p>

    <div class="vouchers-container">
      @for (giftCardControl of extraService.form.controls.giftcardCodes.controls; track $index) {
        <app-gift-card-submittable-order (remove)="removeGiftCard(giftCardControl)" (submit)="submitGiftCard($event, giftCardControl)"></app-gift-card-submittable-order>
      }

      <div class="voucher-wrapper">
        <div class="voucher-add-container">
          <button mat-raised-button color="accent" (click)="addGiftCard()">
            <mat-icon>add</mat-icon>
            @if (extraService.form.controls.giftcardCodes.controls.length === 0) {
              {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.GIFT_CARD.ADD' | translate}}
            }
            @if (extraService.form.controls.giftcardCodes.controls.length > 0) {
              {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.GIFT_CARD.ADD_ANOTHER' | translate}}
            }
          </button>
        </div>
      </div>
    </div>

    <app-form-control-errors [control]="extraService.form.controls.giftcardCodes"></app-form-control-errors>
  </mat-expansion-panel>
</mat-accordion>

@if (((sum$ | async) ?? 0) > 0) {
  <mat-card>
    <mat-card-content>
      @if (translateService.currentLang === 'de-DE') {
        <p>Die Zahlung per Sofort funktioniert derzeit nicht. Sie können mit Giropay oder Kreditkarte bezahlen. Wenn es immer noch nicht funktioniert, kontaktieren Sie uns bitte per E-Mail.</p>
      }
      <h2>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.PAYMENT.PAYMENT_METHOD' | translate}}</h2>
      <app-payment-method-select [formControl]="extraService.form.controls.paymentMethod" [hasCreditCardCharge]="false"></app-payment-method-select>
      <app-form-control-errors [control]="extraService.form.controls.paymentMethod"></app-form-control-errors>
    </mat-card-content>
  </mat-card>
}

<button mat-raised-button class="checkout-button" color="accent" (click)="checkout()" [disabled]="extraService.form.status !== 'VALID'">
  {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.PAY' | translate}}
  <mat-icon iconPositionEnd>navigate_next</mat-icon>
</button>
