import {Component, computed, input, Input, OnDestroy} from '@angular/core';
import {map, Observable, Subscription, switchMap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {AbstractControl} from "@angular/forms";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";


@Component({
  selector: 'app-form-control-errors',
  templateUrl: './form-control-errors.component.html',
  styleUrls: ['./form-control-errors.component.scss']
})
export class FormControlErrorsComponent implements OnDestroy {
  @Input() hugField = false;

  control = input.required<AbstractControl>();
  ignoredErrors = input<string[]>([]);

  private errors$ = toObservable(this.control)
    .pipe(
      switchMap(control => {
        return control.statusChanges
          .pipe(
            map(() => control.errors)
          );
      })
    );

  private errors = toSignal(this.errors$);
  filteredErrorsKeyValue = computed(() => {
    return Object
      .keys(this.errors() ?? {})
      .filter(error => !this.ignoredErrors().includes(error))
      .map(error => ({key: error, value: this.errors()?.[error]}));
  });

  observableErrorMapping: { [error: string]: (obj?: any) => Observable<string> } = {
    generic: () => this.translateService.get('FORMS.ERRORS.GENERIC'),
    min: (details: { min: number; actual: number; }) => this.translateService.get('FORMS.ERRORS.MIN', details),
    max: (details: { max: number; actual: number; }) => this.translateService.get('FORMS.ERRORS.MAX', details),
    minTickets: (details: { min: number; actual: number; }) => this.translateService.get('FORMS.ERRORS.MIN_TICKETS', details),
    minLength: (details: { min: number; actual: number; }) => this.translateService.get('FORMS.ERRORS.MIN_LENGTH', details),
    required: () => this.translateService.get('FORMS.ERRORS.REQUIRED'),
    matDatepickerMin: () => this.translateService.get('FORMS.ERRORS.DATE_IN_PAST'),
    email: () => this.translateService.get('FORMS.ERRORS.EMAIL'),
    forbiddenName: () => this.translateService.get('FORMS.ERRORS.NAME'),
    customRequired: ({translationKey}: {translationKey: string}) => this.translateService.get(translationKey),
    moreVouchersThanPeople: (details: {vouchers: number, people: number}) => this.translateService.get('FORMS.ERRORS.MORE_VOUCHERS_THAN_PEOPLE', details),
    noPeople: () => this.translateService.get('FORMS.ERRORS.NO_PEOPLE'),
    invalidCoupon: (translationKey: string) => this.translateService.get(translationKey),
    phone: () => this.translateService.get('FORMS.ERRORS.PHONE'),
    duplicateVouchers: () => this.translateService.get('FORMS.ERRORS.DUPLICATE_VOUCHERS'),
    requiredUpsell: () => this.translateService.get('FORMS.ERRORS.REQUIRED_UPSELL'),
    invalidDiscountCombination: () => this.translateService.get('FORMS.ERRORS.INVALID_DISCOUNT_COMBINATION'),
    invalidGiftCard: () => this.translateService.get('FORMS.ERRORS.INVALID_GIFT_CARD'),
    duplicateGiftCards: () => this.translateService.get('FORMS.ERRORS.DUPLICATE_GIFT_CARDS'),
  };

  controlSubscription: Subscription | null = null;

  constructor(private translateService: TranslateService) {
  }

  errorHasTranslation(error: string): boolean {
    return error in this.observableErrorMapping;
  }

  ngOnDestroy(): void {
    this.controlSubscription?.unsubscribe();
  }
}
