import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {map, startWith, Subscription, switchMap, tap} from "rxjs";
import {Article, PaymentApiService, type PaymentMethodsPaymentMethod} from "../../api/generated";
import {CurrencyPipe} from "@angular/common";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ErrorService} from "../../services/error.service";
import {tryRecoverFromErrors} from "../../helpers/RecoverableObservable";
import {PaymentService} from "../../services/payment.service";
import {hasRequiredPropertiesFn} from "../../helpers/UtilityFunctions";


@Component({
  selector: 'app-payment-method-select',
  templateUrl: './payment-method-select.component.html',
  styleUrls: ['./payment-method-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: PaymentMethodSelectComponent
    }
  ]
})
export class PaymentMethodSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input() hasCreditCardCharge = false;

  subscriptions = new Subscription();
  paymentMethods: (PaymentMethodsPaymentMethod & {id: string, description: string})[] | null = null;

  creditCardArticle: Article | null = null;

  paymentMethodValue: string | null = null;

  onChange: ((paymentMethod: string | null) => unknown) | null = null;

  onTouched: (() => unknown) | null = null;

  touched = false;

  disabled = false;

  constructor(
    public translateService: TranslateService,
    private paymentApiService: PaymentApiService,
    private paymentService: PaymentService,
    private currencyPipe: CurrencyPipe,
    private errorService: ErrorService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.paymentService.creditCardArticle.subscribe(creditCardArticle => this.creditCardArticle = creditCardArticle);

    const localizedPaymentMethodsSubscription = this.translateService.onLangChange
      .pipe(
        map(langChangeEvent => langChangeEvent.lang),
        startWith(this.translateService.currentLang),
        tap(() => this.setDisabledState(true)),
        switchMap(() => {
          return this.paymentApiService.getApiPaymentMethods()
            .pipe(
              tryRecoverFromErrors(() => this.errorService.showErrorDialogWithRetry())
            );
        }),
        tap(() => this.setDisabledState(false)),
        map(methodsResponse => (methodsResponse.paymentMethods ?? []).filter(hasRequiredPropertiesFn(['id', 'description'])))
      )
      .subscribe(paymentMethods => this.paymentMethods = paymentMethods);

    this.subscriptions.add(localizedPaymentMethodsSubscription);
  }

  get localizedCreditCardCost(): {cost: string | null} {
    return {
      cost: this.currencyPipe.transform(this.creditCardArticle?.price, 'EUR', 'symbol', undefined, this.translateService.currentLang)
    };
  }

  writeValue(paymentMethod: string | null) {
    this.paymentMethodValue = paymentMethod;
    this.onChange?.(paymentMethod);
  }

  registerOnChange(onChange: this["onChange"]) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: this["onTouched"]) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched?.();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
