@if (article) {
  <mat-card  class="no-shadow"
    [ngClass]="responsiveService.activeLayout"
    [class.selected]="(value?.quantity ?? 0) > 0"
    [matTooltip]="'EXTRA_ARTICLE.MORE_INFO_ORDER' | translate"
    matTooltipTouchGestures="off"
    matTooltipPosition="below"
    (click)="openArticleDialog(article)">
    @if (article.imageUrl !== null) {
      <div class="image" [style.background-image]="'url(' + imageBaseUrl + article.imageUrl + ')'">
      </div>
    } @else {
      <div class="image-placeholder"></div>
    }
    <mat-chip class="price-tag">
      {{article.price | currency: 'EUR':'symbol': undefined: translateService.currentLang}}
    </mat-chip>
    @if ((value?.quantity ?? 0) > 0) {
      <mat-chip class="quantity-tag" color="accent" [highlighted]="true">
        {{value?.quantity}}
        <mat-icon [inline]="true" class="text-icon">shopping_basket</mat-icon>
      </mat-chip>
    }
    <mat-card-content>
      <span class="article-name">
        {{article.name}}
      </span>
    </mat-card-content>
  </mat-card>
}

