import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {ApiError, CheckGiftCardResponse, PaymentApiService} from "../../api/generated";
import {BehaviorSubject, concat, EMPTY, filter, map, of, share, Subject, switchMap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {catchError} from "rxjs/operators";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {notNullOrUndefined} from "../../helpers/UtilityFunctions";

@Component({
  selector: 'app-gift-card-submittable-order',
  templateUrl: './gift-card-order-submittable.component.html',
  styleUrl: './gift-card-order-submittable.component.scss',
})
export class GiftCardOrderSubmittableComponent {
  @Output() remove = new EventEmitter<void>();
  @Output() submit = new EventEmitter<CheckGiftCardResponse>();

  control = new FormControl<number | null>(null, {validators: [Validators.required]});

  giftCardInfo$ = new BehaviorSubject<CheckGiftCardResponse | null>(null);
  apply$ = new Subject<void>();
  validating$ = new Subject<boolean>();

  constructor(
    private paymentApiService: PaymentApiService,
    public translateService: TranslateService
  ) {
    const submittedValue$ = this.apply$
      .pipe(
        map(() => this.control.value),
        filter(notNullOrUndefined),
        share()
      );

    const validationStatusWithLoading$ = submittedValue$
      .pipe(
        switchMap((value) => {
          const validation$ = this.paymentApiService
            .postApiPaymentCheckGiftCard({code: value.toString()})
            .pipe(
              catchError((error) => {
                if (error instanceof ApiError && error.status === 404) {
                  this.control.setErrors({invalidGiftCard: true});
                  return EMPTY;
                }

                throw error;
              })
            );

          return concat(of({loading: true}), validation$, of({loading: false}));
        }),
        share()
      );

    validationStatusWithLoading$
      .pipe(
        filter((v): v is {loading: boolean} => 'loading' in v),
        takeUntilDestroyed()
      )
      .subscribe((v) => this.validating$.next(v.loading));

    validationStatusWithLoading$
      .pipe(
        filter((v): v is CheckGiftCardResponse => !('loading' in v)),
        takeUntilDestroyed()
      )
      .subscribe((giftcard) => {
        this.control.setErrors(null);
        this.giftCardInfo$.next(giftcard);
        this.submit.emit(giftcard);
      });
  }

  validateAndApply() {
    this.apply$.next();
  }
}
