import {Component, Input, OnInit} from '@angular/core';
import {Article} from "../../api/generated";
import {environment} from "../../../environments/environment";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ArticleInfoDialogComponent} from "../../dialogs/article-info-dialog/article-info-dialog.component";
import {StaticDialogService} from "../../services/static-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {ResponsiveService} from "../../services/responsive.service";
import {notNullOrUndefined} from "../../helpers/UtilityFunctions";

export interface ArticleValue {
  id: string;
  quantity: number;
}

@Component({
  selector: 'app-article-tile',
  templateUrl: './article-tile.component.html',
  styleUrls: ['./article-tile.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ArticleTileComponent
    }
  ]
})
export class ArticleTileComponent implements OnInit, ControlValueAccessor {
  @Input() article: Article | null = null;
  imageBaseUrl = environment.articleImageBaseUrl;


  value: ArticleValue | null = null;

  onChange: (value: ArticleValue | null) => void = () => {return;};

  onTouched: () => void = () => {return;};

  touched = false;

  disabled = false;

  constructor(
    private staticDialogService: StaticDialogService,
    public translateService: TranslateService,
    public responsiveService: ResponsiveService
  ) {
  }

  ngOnInit(): void {
    if (this.article === null) {
      throw new Error('Product tile input may not be null');
    }
    if (this.article.id === null || this.article.id === undefined) {
      throw new Error('Product id may not be null or undefined');
    }

    this.value = {id: this.article.id, quantity: 0};
    this.onChange(this.value);
  }

  private setQuantity(amount: number) {
    if (this.value === null) {
      return;
    }

    this.value.quantity = amount;
    this.onChange(this.value);
  }

  writeValue(value: ArticleValue | null) {
    this.value = value;
  }

  registerOnChange(onChange: (value: ArticleValue | null) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  openArticleDialog(article: Article) {
    const dialogRef = this.staticDialogService.open(ArticleInfoDialogComponent, {
      data: {
        ...article,
        quantityControl: this as unknown as FormControl<ArticleValue>
      },
      width: '600px',
      height: 'auto',
      backdropClass: 'dark-backdrop'
    });

    dialogRef.afterClosed().subscribe(returnValue => {
      if (notNullOrUndefined(returnValue)) {
        this.setQuantity(returnValue.quantity);
      }
    });
  }
}
