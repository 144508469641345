@if (filteredErrorsKeyValue().length !== 0) {
  <div class="error-display" [class.hug-field]="hugField">
    @if (filteredErrorsKeyValue().length === 1) {
      @for (errorKeyValue of filteredErrorsKeyValue(); track errorKeyValue.key) {
        @if (errorHasTranslation(errorKeyValue.key)) {
          {{ observableErrorMapping[errorKeyValue.key](errorKeyValue.value) | async }}
        } @else {
          {{ observableErrorMapping['generic']() | async}}
        }
      }
    } @else {
      <ul>
        @for (errorKeyValue of filteredErrorsKeyValue(); track errorKeyValue.key) {
          <li>
            @if (errorHasTranslation(errorKeyValue.key)) {
              {{ observableErrorMapping[errorKeyValue.key](errorKeyValue.value) | async }}
            } @else {
              {{ observableErrorMapping['generic']() | async}}
            }
          </li>
        }
      </ul>
    }
  </div>
}
