import {inject, Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  share,
  shareReplay,
  startWith,
  switchMap
} from "rxjs";
import {notNullOrUndefined} from "../../../helpers/UtilityFunctions";
import {ApiError, UpsellApiService} from "../../../api/generated";
import {controlToBehaviorSubject, withLoadingState, withoutLoadingState} from "../../../helpers/CustomRxjsOperators";
import {catchError} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {SupportedLanguage} from "../../../app.component";
import {UpsellService} from "../../../services/upsell.service";
import {ArticleValue} from "../../../components/extra-article-tile/article-tile.component";
import {PaymentService} from "../../../services/payment.service";
import {duplicateGiftCardValidator} from "../../../helpers/Validators";

@Injectable({
  providedIn: 'root'
})
export class ExtraService {

  public form = new FormGroup({
    organisedVisitId: new FormControl<string | null>(null, {validators: [Validators.required]}),
    expositionPeriodId: new FormControl<string | null>(null, {validators: [Validators.required]}),
    additionalArticles: new FormArray<FormControl<ArticleValue>>([]),
    paymentMethod: new FormControl<string | null>(null),
    giftcardCodes: new FormArray<FormControl<number | null>>([], [duplicateGiftCardValidator]),
  });

  private upsellApiService = inject(UpsellApiService);
  private translateService = inject(TranslateService);
  private paymentService = inject(PaymentService);

  public order$ = controlToBehaviorSubject(this.form.controls.organisedVisitId)
    .pipe(
      filter(notNullOrUndefined),
      distinctUntilChanged(),
      switchMap(organisedVisitId => {
        return this.upsellApiService.getApiUpsellOrderinfo(organisedVisitId)
          .pipe(
            withLoadingState(),
            catchError((err) => {
              if (err instanceof ApiError && err.status === 404) {
                return of(null);
              }

              throw err;
            })
          );
      }),
      shareReplay(1)
    );

  private upsellService = inject(UpsellService);

  private availableUpsellArticles$ = this.order$
    .pipe(
      filter(withoutLoadingState),
      filter(notNullOrUndefined),
      map(order => order.expositionId),
      switchMap(expositionId => this.upsellService.getLocalizedArticlesObservable(null, new FormControl(expositionId))),
      shareReplay(1)
    );

  public originalLocalizedArticles$ = this.order$
    .pipe(
      filter(withoutLoadingState),
      filter(notNullOrUndefined),
      delay(1),
      switchMap(order => {
        return this.availableUpsellArticles$
          .pipe(
            filter(withoutLoadingState),
            map(localizedArticles => {
              return order.articles
                .map(article => {
                  return {
                    purchaseInfo: article,
                    localizedArticle: localizedArticles.find(localizedArticle => localizedArticle.id === article.articleId)
                  };
                })
                .filter(({localizedArticle}) => localizedArticle !== undefined)
                .map(article => {
                  return {
                    id: article.localizedArticle!.id,
                    name: article.localizedArticle!.name,
                    description: article.localizedArticle!.description,
                    quantity: article.purchaseInfo.quantity,
                    price: article.localizedArticle!.price,
                    img: article.localizedArticle!.imageUrl
                  };
                });
            }),
            withLoadingState()
          );
      }),
      shareReplay(1)
    );

  public shoppingCard$ = controlToBehaviorSubject(this.form.controls.additionalArticles)
    .pipe(
      switchMap(articles => {
        return this.availableUpsellArticles$
          .pipe(
            filter(withoutLoadingState),
            map(availableArticles => {
              return articles
                .filter(article => article.quantity > 0)
                .map(article => {
                  return {
                    ...availableArticles.find(availableArticle => availableArticle.id === article.id),
                    quantity: article.quantity
                  };
                });
            })
          );
      }),
      shareReplay(1)
    );

  constructor() {
    this.order$
      .pipe(
        filter(withoutLoadingState)
      )
      .subscribe(order => {
        this.form.controls.expositionPeriodId.setValue(order?.expositionPeriodId ?? null);
        this.translateService.use(this.getLanguage(order?.language ?? 'nl'));
      });
  }

  private getLanguage(language: string): SupportedLanguage {
    switch (language.toLowerCase()) {
      case 'en':
        return 'en-US';
      case 'de':
        return 'de-DE';
      case 'nl':
        return 'nl-NL';
      default:
        return 'nl-NL';
    }
  }

  public getBasketTotalObservable(): Observable<number> {
    const formChange$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      delay(0),
      share()
    );

    const articles$ =  this.upsellService
      .getLocalizedArticlesObservable(this.form.controls.expositionPeriodId, null)
      .pipe(
        filter(withoutLoadingState)
      );

    const optionalArticlePrice$ = combineLatest([formChange$, articles$])
      .pipe(
        map(([value, allArticles]) => {
          return (value.additionalArticles ?? [])
            .map(purchasingArticle => (allArticles.find(article => article.id === purchasingArticle.id)?.price ?? 0) * purchasingArticle.quantity)
            .reduce((sum, price) => sum + price, 0);
        })
      );

    const paymentMethodCharge$ = combineLatest([formChange$, this.paymentService.creditCardArticle])
      .pipe(
        map(([value, creditCardArticle]) => value.paymentMethod === 'creditcard' ? creditCardArticle.price ?? 0 : 0)
      );

    return combineLatest([optionalArticlePrice$, paymentMethodCharge$])
      .pipe(
        map(prices => prices.reduce((a, b) => a + b, 0))
      );
  }
}
