<mat-form-field appearance="outline" floatLabel="always" [class.mat-form-field-invalid]="ngControl?.control?.invalid ?? false">
  @if (!withoutLabel) {
    <mat-label><ng-content select="[label-content]"></ng-content></mat-label>
  }
  <button class="number-button accent-icon-button"
    matRipple [matRippleCentered]="true" [matRippleUnbounded]="false"
    mat-icon-button
    matPrefix
    (click)="add(-1); $event.stopPropagation()">
    <mat-icon>remove</mat-icon>
  </button>

  <input matInput type="number" [(ngModel)]="value" (blur)="onBlur()">

  <button class="number-button accent-icon-button"
    matRipple [matRippleCentered]="true" [matRippleUnbounded]="false"
    mat-icon-button
    matSuffix
    (click)="add(1); $event.stopPropagation()">
    <mat-icon>add</mat-icon>
  </button>

  <mat-hint align="end"><ng-content select="[hint]"></ng-content></mat-hint>
</mat-form-field>
