<div mat-dialog-title>{{'DIALOGS.FOOD_ORDER_EXPIRY.TITLE' | translate}}</div>
@if (state === 'warning') {
  <div mat-dialog-content>{{'DIALOGS.FOOD_ORDER_EXPIRY.WARNING' | translate:minutesRemaining}}</div>
}
@if (state === 'expiry') {
  <div mat-dialog-content>{{'DIALOGS.FOOD_ORDER_EXPIRY.EXPIRY' | translate}}</div>
}
<div mat-dialog-actions>
  <button mat-flat-button [matDialogClose]="null">{{'DIALOGS.FOOD_ORDER_EXPIRY.CLOSE' | translate}}</button>
</div>
