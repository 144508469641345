<mat-card>
  <mat-card-content>
    <h1>{{'COMPONENTS.UPSELL.GENERAL.TITLE' | translate}}</h1>
    @if (customBodyTemplate === null) {
      <p>{{'COMPONENTS.UPSELL.GENERAL.BODY' | translate}}</p>
    }
    @if (customBodyTemplate !== null) {
      <ng-container *ngTemplateOutlet="customBodyTemplate"></ng-container>
    }

    @if (!anyArticleSelected && !required) {
      <button mat-flat-button matStepperNext style="width: 100%;">{{'COMPONENTS.UPSELL.SKIP' | translate}}</button>
      <br/><br/>
    }

    <h2>{{'COMPONENTS.UPSELL.LABEL' | translate}}</h2>

    @if (loading()) {
      <div class="article-loader">
        <mat-spinner></mat-spinner>
        <div>{{'COMPONENTS.UPSELL.ARTICLES.SEARCHING' | translate}}</div>
      </div>
    } @else {

      @for (category of groupedArticles(); track category.order) {
        <h3>{{category.translationKey | translate}}</h3>

        <div class="products-container">
          @for (article of category.articles; track article.id) {
            <app-article-tile [article]="article" [formControl]="article.control">
            </app-article-tile>
          }
        </div>
      }

<!--      @if (articles().length > 0) {-->
<!--        <div class="products-container">-->
<!--          @for (article of articles(); track article.id) {-->
<!--            <app-article-tile [article]="article" [formControl]="article.control">-->
<!--            </app-article-tile>-->
<!--          }-->
<!--        </div>-->
<!--      }-->
    }
  </mat-card-content>
</mat-card>

<app-form-control-errors [control]="articlesFormArray()"></app-form-control-errors>


