import {Component, input, Input, signal, TemplateRef} from '@angular/core';
import {FormArray, FormControl, Validators} from "@angular/forms";
import {ArticleValue} from "../extra-article-tile/article-tile.component";
import {filter, map, merge, Observable, shareReplay, switchMap} from "rxjs";
import {Article} from "../../api/generated";
import {TranslateService} from "@ngx-translate/core";
import {UpsellService} from "../../services/upsell.service";
import {hasRequiredPropertiesFn, notNullOrUndefined} from "../../helpers/UtilityFunctions";
import {isLoadingState, withoutLoadingState} from "../../helpers/CustomRxjsOperators";
import {marker} from "@biesbjerg/ngx-translate-extract-marker";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";

type ExtendedArticle = Article & { control: FormControl<ArticleValue> };
type Category = {order: number, translationKey: string, key: string};

const categoryInfo: {[key: string]: Category} = {
  "AAN DE WAL": {order: 1, translationKey: marker('COMPONENTS.UPSELL.CATEGORIES.AAN_DE_WAL'), key: 'AAN DE WAL'},
  CANCEL: {order: 2, translationKey: marker('COMPONENTS.UPSELL.CATEGORIES.CANCEL'), key: 'CANCEL'},
  FOOD: {order: 3, translationKey: marker('COMPONENTS.UPSELL.CATEGORIES.FOOD'), key: 'FOOD'},
  DRANK: {order: 4, translationKey: marker('COMPONENTS.UPSELL.CATEGORIES.DRINKS'), key: 'DRANK'},
  OTHER: {order: 5, translationKey: marker('COMPONENTS.UPSELL.CATEGORIES.OTHER'), key: 'OTHER'},
};

const extraOrderCategoryBlackList: [keyof typeof categoryInfo] = ['CANCEL'];

@Component({
  selector: 'app-upsell-articles',
  templateUrl: './upsell-articles.component.html',
  styleUrls: ['./upsell-articles.component.scss']
})
export class UpsellArticlesComponent {

  expositionPeriodIdControl = input<FormControl<string | null>>();
  expositionIdControl = input<FormControl<string | null>>();
  articlesFormArray = input.required<FormArray<FormControl<ArticleValue>>>();

  @Input() required = false;
  @Input() customBodyTemplate: TemplateRef<unknown> | null = null;

  isExtraOrder = input<boolean>(false);

  //articles = signal<Array<ExtendedArticle>>([]);
  groupedArticles = signal<Array<Category & {articles: ExtendedArticle[]}>>([]);
  loading = signal(false);

  constructor(
    private translateService: TranslateService,
    private upsellService: UpsellService
  ) {
    const {articles$, loading$} = this.getArticleObservables();

    loading$
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(loading => this.loading.set(loading));

    articles$
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(articles => {
        let groupedArticles = this.groupArticlesByCategory(articles);

        if (this.isExtraOrder()) {
          groupedArticles = groupedArticles.filter(category => !extraOrderCategoryBlackList.includes(category.key));
        }

        this.groupedArticles.set(groupedArticles);

        this.articlesFormArray().clear();
        articles.forEach(article => this.articlesFormArray().push(article.control));
      });
  }

  private groupArticlesByCategory(articles: Array<ExtendedArticle>) {
    const categoryKeys = Object.keys(categoryInfo);

    return categoryKeys
      .map(categoryKey => ({categoryKey, category: categoryInfo[categoryKey]}))
      .sort((a, b) => a.category.order - b.category.order)
      .map(({categoryKey, category}) => {
        const categoryArticles = articles.filter(article => {
          if (categoryKey === 'OTHER') {
            return !categoryKeys.some(key => article.categories?.includes(key));
          }

          return article.categories?.includes(categoryKey) ?? false;
        });

        return {
          ...category,
          articles: categoryArticles
        };
      })
      .filter(category => category.articles.length > 0);
  }

  get anyArticleSelected() {
    return this.articlesFormArray().controls.some(articleControl => articleControl.value.quantity > 0);
  }

  private getArticleObservables() {
    const controls$: Array<Observable<{expositionPeriodId?: FormControl<string | null>, expositionId?: FormControl<string | null>}>> = [
      toObservable(this.expositionPeriodIdControl).pipe(map(value => ({expositionPeriodId: value}))),
      toObservable(this.expositionIdControl).pipe(map(value => ({expositionId: value})))
    ];

    const articlesOrLoading$ = merge(...controls$)
      .pipe(
        filter(({expositionPeriodId, expositionId}) => notNullOrUndefined(expositionPeriodId) || notNullOrUndefined(expositionId)),
        switchMap(({expositionPeriodId, expositionId}) => this.upsellService.getLocalizedArticlesObservable(expositionPeriodId ?? null, expositionId ?? null)),
        shareReplay({refCount: true, bufferSize: 1})
      );

    const loading$ = articlesOrLoading$
      .pipe(
        filter(isLoadingState),
        map(loadingState => loadingState.loading)
      );

    const articles$ = articlesOrLoading$
      .pipe(
        filter(withoutLoadingState),
        map(articles => {
          return articles
            .filter(hasRequiredPropertiesFn(['id']))
            .map(article => {
              const existingFormControl = this.articlesFormArray().controls.find(control => control.value.id == article.id);
              const control = existingFormControl ?? new FormControl<ArticleValue>({id: article.id, quantity: 0}, {nonNullable: true});

              if (notNullOrUndefined(article.maximumOrderQuantity) && article.maximumOrderQuantity > 0) {
                control.addValidators([Validators.max(article.maximumOrderQuantity)]);
              }

              return {...article, control};
            });
        })
      );

    return {articles$, loading$};
  }
}
