import {AfterViewInit, Component, Injector, Input, OnChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";

@Component({
  selector: 'app-number-control',
  templateUrl: './number-control.component.html',
  styleUrls: ['./number-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: NumberControlComponent
    }
  ]
})
export class NumberControlComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() withoutLabel = false;
  @Input() minimum: number | null = null;
  @Input() maximum: number | null = null;

  disabled = false;
  private _value: number | null = null;
  set value(value: number | null) {
    this._value = value;

    if (value !== null) {
      if (this.minimum !== null && value < this.minimum) {
        this._value = this.minimum;
      }
      if (this.maximum !== null && value > this.maximum) {
        this._value = this.maximum;
      }
    }

    this.onChanged?.(this._value);
  }
  get value() {
    return this._value;
  }

  ngControl: NgControl | null = null;

  onChanged: ((value: number | null) => void) | null = null;
  onTouched: (() => void) | null = null;

  constructor(
    private injector: Injector
  ) {

  }

  ngAfterViewInit() {
    this.ngControl = this.injector.get(NgControl, null);
  }

  ngOnChanges() {
    if (this.minimum !== null) {
      if (this.value !== null && this.value < this.minimum) {
        this.value = this.minimum;
      }
    }

    if (this.maximum !== null) {
      if (this.value !== null && this.value > this.maximum) {
        this.value = this.maximum;
      }
    }
  }

  registerOnChange(fn: NumberControlComponent['onChanged']): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: NumberControlComponent['onTouched']): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  add(number: number) {
    this.value = (this.value ?? 0) + number;
    this.onTouched?.();
  }

  onBlur() {
    this.onTouched?.();
  }
}
