import {Component} from '@angular/core';

@Component({
  selector: 'app-sending-transaction',
  templateUrl: './sending-transaction.component.html',
  styleUrls: ['./sending-transaction.component.scss']
})
export class SendingTransactionComponent {

}
