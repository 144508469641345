@if (article !== null) {
  <button mat-icon-button class="dialog-close-button" [mat-dialog-close]="null">
    <mat-icon>close</mat-icon>
  </button>
  <div mat-dialog-content>
    @if (articleImageUrls.length > 0) {
      <swiper-container appSwiper [config]="swiperConfig">
        @for (imageUrl of articleImageUrls; track imageUrl) {
          <swiper-slide class="articleImage" [style.background-image]="'url(' + imageUrl + ')'">
          </swiper-slide>
        }
      </swiper-container>
    }
    <h2>
      {{articleTitle}}
      <br>
        <small>{{article.price | currency:'EUR'}}</small>
      </h2>
      <app-number-control [minimum]="0" [formControl]="quantityControl">
        <span label-content>{{'EXTRA_ARTICLE.DIALOG.ORDER_QUANTITY_LABEL' | translate}}</span>
        @if ((article.minimumOrderQuantity ?? 0) > 1) {
          <span hint>{{'EXTRA_ARTICLE.DIALOG.MINIMUM_QUANTITY' | translate:article}}</span>
        }
      </app-number-control>
      <p class="article-description">{{article.description}}</p>
    </div>
    <div mat-dialog-actions>
      <button class="submit-button" mat-raised-button color="accent" (click)="submit()">
        {{buttonText}}
      </button>
    </div>
  }
