@if (paymentMethods) {
  <p>{{'PAYMENT_OPTIONS.SLOW_ORDER_WARNING' | translate}}</p>

  <mat-radio-group (change)="writeValue($event.value)" [value]="paymentMethodValue">
    @for (paymentMethod of paymentMethods; track paymentMethod.id) {
      <mat-card class=""
        (click)="writeValue(paymentMethod.id)"
        [class.selected]="paymentMethodValue === paymentMethod.id"
        [class.disabled]="disabled">
        <mat-card-header>
          <mat-card-title>
            <mat-radio-button [value]="paymentMethod.id">{{paymentMethod.description}}</mat-radio-button>
          </mat-card-title>
          @if (paymentMethod.id === 'creditcard' && hasCreditCardCharge) {
            <mat-card-subtitle>
              {{'PAYMENT_OPTIONS.EXTRA_CREDIT_CARD_COSTS' | translate:localizedCreditCardCost}}
            </mat-card-subtitle>
          }
          <div class="icon-container">
            @if (paymentMethod.svg) {
              <img [src]="paymentMethod.svg">
            }
          </div>
        </mat-card-header>
        <mat-card-content>
        </mat-card-content>
      </mat-card>
    }
  </mat-radio-group>
}
