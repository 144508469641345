import {AfterViewInit, Component, inject, ViewChild} from '@angular/core';
import {MatStep, MatStepContent, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {TranslateModule} from "@ngx-translate/core";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {ExtraService} from "./extra.service";
import {ExtraStep1Component} from "./extra-step-1/extra-step-1.component";
import {ExtraStep2Component} from "./extra-step-2/extra-step-2.component";
import {ExtraStep3Component} from "./extra-step-3/extra-step-3.component";
import {ActivatedRoute} from "@angular/router";
import {
  hasRequiredPropertiesFn,
  makeStepperIndicatorMethod,
  notNullOrUndefined,
  throwCoalesceException
} from "../../../helpers/UtilityFunctions";
import {environment} from "../../../../environments/environment";
import {filter, map, share, switchMap, take, timer} from "rxjs";
import {DateTime} from "luxon";
import {FoodOrderExpiryComponent} from "../../../dialogs/food-order-expiry/food-order-expiry.component";
import {withoutLoadingState} from "../../../helpers/CustomRxjsOperators";
import {MatDialogRef} from "@angular/material/dialog";
import {StaticDialogService} from "../../../services/static-dialog.service";
import {UpsellService} from "../../../services/upsell.service";
import {FormControl} from "@angular/forms";
import {ArticleValue} from "../../../components/extra-article-tile/article-tile.component";

@Component({
  selector: 'app-extra',
  standalone: true,
  imports: [
    MatStep,
    MatStepContent,
    MatStepLabel,
    MatStepper,
    TranslateModule,
    ExtraStep1Component,
    ExtraStep2Component,
    ExtraStep3Component
  ],
  templateUrl: './extra.component.html',
  styleUrl: './extra.component.scss'
})
export class ExtraComponent implements AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper | null = null;

  private extraService = inject(ExtraService);

  form = this.extraService.form;

  private activatedRoute = inject(ActivatedRoute);
  private staticDialogService = inject(StaticDialogService);
  private upsellService = inject(UpsellService);

  foodOrderExpireWarningDialog: MatDialogRef<unknown> | null = null;

  constructor() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.extraService.form.controls.organisedVisitId.setValue(params['o']);
    });
  }

  ngAfterViewInit() {
    const stepper = this.stepper ?? throwCoalesceException('Invariant, stepper may not be null');
    stepper._getIndicatorType = makeStepperIndicatorMethod(stepper);

    this.trackFoodOrderPossibility();

    if (!environment.production) {
      // this.loadDebuggingValues(this.stepper ?? throwCoalesceException('Invariant, stepper may not be null'));
    }
  }

  skipValidationOnBackNavigation(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex > event.selectedIndex) {
      event.previouslySelectedStep.interacted = false;
    }
  }

  private trackFoodOrderPossibility() {
    const maximumFoodOrderDate$ = this.extraService.order$
      .pipe(
        filter(withoutLoadingState),
        filter(notNullOrUndefined),
        filter(hasRequiredPropertiesFn(['startDate'])),
        map(boat => DateTime.fromISO(boat.startDate).minus({day: 1}).set({hour: 17, minute: 0, second: 5, millisecond: 0})),
        filter(date => date > DateTime.now()),

        // setTimeout which is used under the hood for RXJS timer, uses 32bit number for milliseconds. This far in the future, don't even bother with expiry timers
        filter(date => date.diffNow().milliseconds < Math.pow(2, 31) - 1),
        share()
      );

    maximumFoodOrderDate$
      .pipe(
        map(date => date.minus({millisecond: environment.foodOrderWarningMillis})),
        switchMap(date => timer(date.toJSDate()))
      )
      .subscribe(() => this.foodOrderExpireWarningDialog = this.staticDialogService.open(FoodOrderExpiryComponent, {data: {state: 'warning'}}));

    maximumFoodOrderDate$
      .pipe(switchMap(date => timer(date.toJSDate())))
      .subscribe(() => {
        this.foodOrderExpireWarningDialog?.close();
        this.staticDialogService.open(FoodOrderExpiryComponent, {data: {state: 'expiry'}});
        this.removeFoodItems();
      });
  }

  private removeFoodItems() {
    this.upsellService.update();
  }

  private loadDebuggingValues(stepper: MatStepper) {
    this.extraService.order$
      .pipe(
        filter(withoutLoadingState),
        filter(notNullOrUndefined),
        take(1)
      )
      .subscribe(() => {
        stepper.steps.forEach(step => step.interacted = true);
        stepper.selectedIndex = 2;
      });

    this.upsellService
      .getLocalizedArticlesObservable(this.form.controls.expositionPeriodId, null)
      .pipe(
        filter(withoutLoadingState),
        take(1)
      )
      .subscribe(articles => {
        articles
          .filter(hasRequiredPropertiesFn(['id']))
          .forEach(article => {
            this.form.controls.additionalArticles.push(
              new FormControl<ArticleValue>({
                id: article.id,
                quantity: (article.minimumOrderQuantity ?? 0) + Math.floor(Math.random() * 5)
              }, {nonNullable: true}));
          });
      });
  }
}
