import {Component, inject} from '@angular/core';
import {MatCard, MatCardContent, MatCardFooter, MatCardSubtitle, MatCardTitle} from "@angular/material/card";
import {PipesModule} from "../../../../pipes/pipes.module";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  MatList,
  MatListItem,
  MatListItemIcon,
  MatListItemLine,
  MatListItemMeta,
  MatListItemTitle
} from "@angular/material/list";
import {AsyncPipe, CurrencyPipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatRipple} from "@angular/material/core";
import {ExtraService} from "../extra.service";
import {filter, ObservedValueOf} from "rxjs";
import {withoutLoadingState} from "../../../../helpers/CustomRxjsOperators";
import {MatTooltip} from "@angular/material/tooltip";
import {PaymentService} from "../../../../services/payment.service";

@Component({
  selector: 'app-order-summary',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardSubtitle,
    MatCardTitle,
    PipesModule,
    TranslateModule,
    MatList,
    CurrencyPipe,
    MatIcon,
    MatListItem,
    MatListItemIcon,
    MatListItemLine,
    MatListItemMeta,
    MatListItemTitle,
    MatRipple,
    AsyncPipe,
    MatTooltip,
    MatCardFooter
  ],
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss'
})
export class OrderSummaryComponent {
  extraService = inject(ExtraService);
  translateService = inject(TranslateService);
  paymentService = inject(PaymentService);

  articles$ = this.extraService.shoppingCard$;

  sum$ = this.extraService.getBasketTotalObservable();

  removeArticleFromOrder(article: ObservedValueOf<typeof this.articles$>[0]) {
    const articleControl = this.extraService.form.controls.additionalArticles.controls.find(control => control.value.id === article.id);

    if (articleControl !== undefined) {
      articleControl.setValue({
        ...articleControl.value,
        quantity: 0
      });
    }
  }
}
