<mat-card>
  <mat-card-content>
    <mat-card-title>{{'PAGES.PRODUCTS.EXTRA.ORDER_SUMMARY.YOUR_ORDER' | translate}}</mat-card-title>


    <mat-list>
      @for (article of (articles$ | async); track article.id) {
        <mat-list-item>
          <mat-icon matListItemIcon
                    matRipple
                    [matRippleCentered]="true"
                    [matRippleUnbounded]="true"
                    class="remove-button"
                    matTooltipPosition="below"
                    [matTooltip]="'PAGES.PRODUCTS.RENTAL_BOAT.ORDER_SUMMARY.REMOVE_FROM_ORDER' | translate"
                    (click)="removeArticleFromOrder(article)">
            close
          </mat-icon>

          <span matListItemTitle [title]="article.name">{{article.name}}</span>
          <span matListItemLine>{{article.quantity}} x {{article.price | currency: 'EUR':'symbol': undefined: translateService.currentLang}}</span>
          <span matListItemMeta>{{(article.quantity * (article.price ?? 0)) | currency: 'EUR':'symbol': undefined: translateService.currentLang}}</span>
        </mat-list-item>
      }

      @if (extraService.form.controls.paymentMethod.value === 'creditcard') {
        <mat-list-item>
          <mat-icon matListItemIcon>credit_card</mat-icon>
          <span matListItemTitle [title]="'PAGES.PRODUCTS.RENTAL_BOAT.PAYMENT_OPTIONS.CREDIT_CARD' | translate">
          {{'PAGES.PRODUCTS.RENTAL_BOAT.PAYMENT_OPTIONS.CREDIT_CARD' | translate}}
        </span>
          <span matListItemLine>
          1 x {{(paymentService.creditCardArticle | async)?.price | currency: 'EUR':'symbol': undefined: translateService.currentLang}}
        </span>
          <span matListItemMeta>
          {{(paymentService.creditCardArticle | async)?.price | currency: 'EUR':'symbol': undefined: translateService.currentLang}}
        </span>
        </mat-list-item>
      }
    </mat-list>
  </mat-card-content>
  <mat-card-footer>
    <div class="totals">
      <span>{{'PAGES.PRODUCTS.RENTAL_BOAT.ORDER_SUMMARY.TOTAL' | translate}}</span>
      <span>{{sum$ | async | currency: 'EUR':'symbol': undefined: translateService.currentLang}}</span>
    </div>
  </mat-card-footer>
</mat-card>
