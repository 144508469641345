import {ChangeDetectorRef, Component, inject, input} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {ComponentsModule} from "../../../../components/components.module";
import {MatCard, MatCardContent} from "@angular/material/card";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ExtraService} from "../extra.service";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {OrderSummaryComponent} from "../order-summary/order-summary.component";
import {AsyncPipe} from "@angular/common";
import {filter, map} from "rxjs";
import {notNullOrUndefined, throwCoalesceException} from "../../../../helpers/UtilityFunctions";
import {CheckGiftCardResponse, PaymentApiService} from "../../../../api/generated";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader, MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {LoadingService} from "../../../../services/loading.service";

@Component({
  selector: 'app-extra-step-3',
  standalone: true,
  imports: [
    ComponentsModule,
    MatCard,
    MatCardContent,
    TranslateModule,
    ReactiveFormsModule,
    OrderSummaryComponent,
    AsyncPipe,
    MatAccordion,
    MatButton,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon
  ],
  templateUrl: './extra-step-3.component.html',
  styleUrl: './extra-step-3.component.scss'
})
export class ExtraStep3Component {
  stepper = input.required<MatStepper>();
  isActive = input.required<boolean>();

  paymentApiService = inject(PaymentApiService);
  translateService = inject(TranslateService);
  loadingService = inject(LoadingService);
  extraService = inject(ExtraService);
  cdr = inject(ChangeDetectorRef);

  sum$ = this.extraService.getBasketTotalObservable();

  constructor() {
    this.extraService.form.valueChanges
      .pipe(
        filter(() => this.isActive()),
        map(form => form.additionalArticles?.every(article => article.quantity === 0) || false),
        filter(anyArticleSelected => anyArticleSelected)
      )
      .subscribe(() => this.stepper().previous());
  }

  checkout() {
    if (this.extraService.form.invalid) {
      return;
    }

    const formValue = this.extraService.form.getRawValue();

    try {
      this.paymentApiService
        .postApiPaymentCheckoutExtra({
          additionalArticles: formValue.additionalArticles,
          giftcardCodes: formValue.giftcardCodes.filter(notNullOrUndefined).map(giftcard => giftcard.toString()),
          organisedVisitId: formValue.organisedVisitId ?? throwCoalesceException('Invariant, organisedVisitId may not be null'),
          paymentMethodId: formValue.paymentMethod ?? throwCoalesceException('Invariant, paymentMethod may not be null')
        })
        .pipe(
          this.loadingService.openTransactionSendingDialogFn()
        )
        .subscribe((response) => {
          if (response.paymentUrl !== null && response.paymentUrl !== undefined) {
            location.href = response.paymentUrl;
          }
        });
    } catch {
      // Should have failed in validation step instead.
      return;
    }
  }

  addGiftCard() {
    this.extraService.form.controls.giftcardCodes.push(new FormControl<number | null>(null));
    this.cdr.detectChanges();
  }

  removeGiftCard(giftCardControl: FormControl<number | null>) {
    const index = this.extraService.form.controls.giftcardCodes.controls.indexOf(giftCardControl);

    if (index !== undefined && index !== null && index >= 0) {
      this.extraService.form.controls.giftcardCodes.removeAt(index, {emitEvent: true});
      this.extraService.form.controls.giftcardCodes.updateValueAndValidity({onlySelf: false, emitEvent: true});
      this.extraService.form.controls.giftcardCodes.markAsDirty();
    }
  }

  submitGiftCard($event: CheckGiftCardResponse, giftCardControl: FormControl<number | null>) {
    const alreadyExists = this.extraService.form.controls.giftcardCodes.value.includes(parseInt($event.code));
    if (alreadyExists) {
      this.extraService.form.controls.giftcardCodes.removeAt(this.extraService.form.controls.giftcardCodes.controls.indexOf(giftCardControl));
      return;
    }

    giftCardControl.setValue(parseInt($event.code));
  }
}
