<div class="gift-card">
  <div class="gift-card-header">
    <div class="bootjes-en-broodjes-logo"></div>
    <span class="title">{{'PAGES.PRODUCTS.GIFTCARD.PRODUCT_NAME' | translate}}</span>
  </div>

  <div class="gift-card-form">
    @if ((giftCardInfo$ | async); as giftCardInfo) {
      <div class="gift-card-amount-wrapper">
        <span class="amount">{{giftCardInfo.code}}</span>
      </div>
      <div class="gift-card-footer">
        <div class="expiry-title">
          Geldig tot: {{giftCardInfo.expiryDate | dutchDate: 'EEEE d MMMM yyyy':'europe/amsterdam':translateService.currentLang}}
        </div>
      </div>
    } @else {
      <div class="gift-card-input">
        <mat-form-field appearance="outline">
          <mat-label>{{'PAGES.PRODUCTS.GIFTCARD.CODE_INPUT_LABEL' | translate}}</mat-label>
          <input matInput type="number" (keydown.enter)="validateAndApply()" [formControl]="control" placeholder="{{'PAGES.PRODUCTS.GIFTCARD.CODE_INPUT_PLACEHOLDER' | translate}}">

          @if (validating$ | async) {
            <mat-spinner [diameter]="36" [strokeWidth]="6" matSuffix color="primary"></mat-spinner>
          } @else {
            <button mat-icon-button matSuffix (click)="validateAndApply()">
              <mat-icon>send</mat-icon>
            </button>
          }
        </mat-form-field>

        <app-form-control-errors [control]="control" [hugField]="true"></app-form-control-errors>
      </div>
    }
  </div>
</div>

<div class="delete-button">
  <button mat-icon-button (click)="remove.emit()">
    <mat-icon>delete</mat-icon>
  </button>
</div>


