import {Component, OnDestroy, OnInit} from '@angular/core';
import {StaticDialog} from "../../services/static-dialog.service";
import {DateTime} from "luxon";
import {Subscription, timer} from "rxjs";

@Component({
  selector: 'app-food-order-expiry',
  templateUrl: './food-order-expiry.component.html',
  styleUrls: ['./food-order-expiry.component.scss']
})
export class FoodOrderExpiryComponent implements StaticDialog<{state: 'warning' | 'expiry'}, null>, OnInit, OnDestroy {
  state: 'warning' | 'expiry' | null = null;

  minutesRemaining: {minutes: string} | null = null;

  subscriptions = new Subscription();

  getDialogInput(input: { state: "warning" | "expiry" } | null): void {
    this.state = input?.state ?? null;
  }

  getValue(): null {
    return null;
  }

  ngOnInit(): void {
    const timeUpdateSubscription = timer(0, 1000).subscribe(() => {
      const formatTime = (number: number) => String(Math.round(number)).padStart(2, '0');

      const fivePm = DateTime.now().set({hour: 17, minute: 0, second: 0});
      const toFive = fivePm.diff(DateTime.now(), ['minute', 'second']);

      const minutes = formatTime(toFive.minutes);
      const seconds = formatTime(toFive.seconds);
      this.minutesRemaining = {minutes: `${minutes}:${seconds}`};
    });

    this.subscriptions.add(timeUpdateSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
