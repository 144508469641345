<div class="step-content" [ngClass]="responsiveService.activeLayout">
  <app-upsell-articles [isExtraOrder]="true"
                       [required]="true"
                       [articlesFormArray]="extraService.form.controls.additionalArticles"
                       [expositionPeriodIdControl]="extraService.form.controls.expositionPeriodId">
  </app-upsell-articles>
  @if (anyArticleSelected) {
    <button mat-raised-button matStepperNext color="accent">
      {{'FORMS.NEXT' | translate}}
      <mat-icon iconPositionEnd>navigate_next</mat-icon>
    </button>
  } @else {
    <button mat-raised-button matStepperNext color="accent" [disabled]="true">
      {{'FORMS.NEXT' | translate}}
      <mat-icon iconPositionEnd>navigate_next</mat-icon>
    </button>
  }
</div>
