import {Injectable} from '@angular/core';
import {filter, OperatorFunction, share} from "rxjs";
import {isLoadingState, withLoadingState} from "../helpers/CustomRxjsOperators";
import {SendingTransactionComponent} from "../dialogs/sending-transaction/sending-transaction.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(
    private matDialog: MatDialog
  ) { }

  public openTransactionSendingDialogFn<T>(): OperatorFunction<T, T> {
    return source => {
      let loaderRef: MatDialogRef<unknown> | null = null;

      const sourceShared$ = source.pipe(share());

      sourceShared$
        .pipe(
          withLoadingState(),
          filter(isLoadingState)
        )
        .subscribe({
          next: state => {
            if (state.loading) {
              loaderRef = this.matDialog.open(SendingTransactionComponent, { disableClose: true, width: '600px'});
            } else {
              loaderRef?.close();
            }
          },
          error: () => {
            loaderRef?.close();
          },
          complete: () => {
            loaderRef?.close();
          }
        });

      return sourceShared$;
    };
  }
}
