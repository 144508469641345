<mat-stepper labelPosition="bottom" animationDuration="0" [linear]="true" (selectionChange)="skipValidationOnBackNavigation($event)" #stepper>

  <mat-step #step1 [stepControl]="form.controls.expositionPeriodId">
    <ng-template matStepLabel>
      {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.LABEL' | translate}}
    </ng-template>
    <ng-template matStepContent>
      <app-extra-step-1 class="step-content"
                 [isActive]="stepper.selectedIndex === stepper.steps.toArray().indexOf(step1)"
                 [stepper]="stepper">
      </app-extra-step-1>
    </ng-template>
  </mat-step>

  <mat-step #step2 [stepControl]="form.controls.organisedVisitId">
    <ng-template matStepLabel>
      {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_2.LABEL' | translate}}
    </ng-template>
    <ng-template matStepContent>
      <app-extra-step-2 class="step-content"
                 [isActive]="stepper.selectedIndex === stepper.steps.toArray().indexOf(step2)"
                 [stepper]="stepper">
      </app-extra-step-2>
    </ng-template>
  </mat-step>

  <mat-step #step3 [stepControl]="form.controls.organisedVisitId">
    <ng-template matStepLabel>
      {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.LABEL' | translate}}
    </ng-template>
    <ng-template matStepContent>
      <app-extra-step-3 class="step-content"
                 [isActive]="stepper.selectedIndex === stepper.steps.toArray().indexOf(step3)"
                 [stepper]="stepper">
      </app-extra-step-3>
    </ng-template>
  </mat-step>

</mat-stepper>
