import {Component, inject, input} from '@angular/core';
import {MatStepper, MatStepperNext} from "@angular/material/stepper";
import {ComponentsModule} from "../../../../components/components.module";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {ResponsiveService} from "../../../../services/responsive.service";
import {ExtraService} from "../extra.service";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-extra-step-2',
  standalone: true,
  imports: [
    ComponentsModule,
    MatButton,
    MatIcon,
    MatStepperNext,
    TranslateModule,
    NgClass
  ],
  templateUrl: './extra-step-2.component.html',
  styleUrl: './extra-step-2.component.scss'
})
export class ExtraStep2Component {
  stepper = input.required<MatStepper>();
  isActive = input.required<boolean>();

  extraService = inject(ExtraService);
  responsiveService = inject(ResponsiveService);

  get anyArticleSelected() {
    return this.extraService.form.controls.additionalArticles.controls.some(articleControl => articleControl.value.quantity > 0);
  }
}
