<div class="step-content" [ngClass]="responsiveService.activeLayout">
  <mat-card>
    <mat-card-content>
      <h1>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.TITLE' | translate}}</h1>
      <p [innerHTML]="'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDER_BODY' | translate"></p>

      @if (order() === null) {
        <div class="alert alert-danger">
          <strong>
            {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.UNKNOWN' | translate}}
          </strong>
        </div>
      }

      @if (order(); as o) {
        <h2>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDER_TITLE' | translate}}</h2>
        <p>
          {{o.personName}} - {{o.personEmail}}
        </p>

        <p>
          {{orderTitle()}}
          <br>
          {{o.startDate | dutchDate: 'EEEE d MMMM yyyy':'europe/amsterdam': translateService.currentLang}}

          <strong>
            {{o.startDate | dutchDate: 'HH:mm':'europe/amsterdam': translateService.currentLang}}
            -
            {{o.endDate | dutchDate: 'HH:mm':'europe/amsterdam': translateService.currentLang}}
          </strong>
        </p>

        @if (articles$ | async) {
          <h2>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDERED_ARTICLES_TITLE' | translate}}</h2>

          <mat-list>
            @for (article of (articles$ | async); track article.id; let last = $last) {
              <mat-list-item>
                <img matListItemIcon [src]="imageBaseUrl + article.img"/>
                <span matListItemTitle>{{article.name}}</span>
                <span matListItemLine>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.QUANTITY' | translate:{quantity: article.quantity} }}</span>
              </mat-list-item>
            }
          </mat-list>
        }
      }

      @if (loading()?.loading === true && order() !== null) {
        <div class="loading-wrapper">
          <mat-spinner></mat-spinner>
          <p>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.LOADING' | translate}}</p>
        </div>
      }
    </mat-card-content>
  </mat-card>

  @if (order()) {
    <button mat-raised-button matStepperNext color="accent">
      {{'FORMS.NEXT' | translate}}
      <mat-icon iconPositionEnd>navigate_next</mat-icon>
    </button>
  }
</div>



